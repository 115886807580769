import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
<path d="M2840 11736 c-179 -50 -307 -197 -330 -381 -7 -54 -10 -1195 -8
-3560 l3 -3480 21 -58 c60 -161 195 -274 357 -298 84 -12 6962 -12 7047 1 162
23 306 151 358 319 16 53 17 254 17 3571 0 3305 -1 3518 -17 3571 -51 163
-179 284 -336 318 -81 17 -7050 14 -7112 -3z m7113 -130 c94 -28 181 -115 210
-210 9 -32 12 -790 12 -3546 0 -3416 0 -3506 -19 -3559 -23 -67 -91 -143 -161
-179 l-50 -27 -3527 -3 c-3518 -2 -3527 -2 -3581 18 -68 26 -142 96 -176 170
l-26 55 0 3525 0 3525 33 67 c38 78 92 129 166 157 50 20 104 20 3562 20 2953
1 3518 -1 3557 -13z"/>
<path d="M5996 11254 c-4 -9 -6 -679 -6 -1488 0 -1158 -3 -1476 -13 -1489 -11
-16 -117 -17 -1482 -20 l-1470 -2 -3 -87 -3 -88 1441 0 1441 0 -8 -37 c-4 -21
-8 -85 -7 -143 1 -86 6 -118 27 -180 15 -41 28 -79 29 -85 2 -7 -501 -11
-1490 -13 l-1493 -2 3 -88 3 -87 1498 -3 c1416 -2 1497 -3 1499 -20 2 -9 3
-681 3 -1492 l0 -1475 90 0 90 0 3 1468 c1 807 5 1467 8 1467 3 0 39 -16 78
-35 105 -52 230 -73 341 -57 47 7 92 15 100 18 13 5 15 -155 17 -1427 l3
-1434 77 -9 c42 -5 83 -6 92 -3 15 6 16 140 16 1484 l0 1478 23 15 c19 13 209
16 1467 20 l1445 5 3 88 3 87 -1382 0 -1381 0 5 23 c3 12 15 49 27 81 35 97
37 293 4 381 -5 13 150 15 1377 17 l1384 3 3 88 3 87 -1425 0 -1424 0 -56 54
-56 53 0 1412 c0 955 -3 1419 -10 1432 -9 16 -22 19 -90 19 l-80 0 0 -1379 c0
-759 -4 -1382 -8 -1385 -5 -3 -33 1 -63 9 -83 22 -233 19 -315 -6 -38 -12 -86
-30 -106 -40 -21 -11 -42 -19 -48 -19 -7 0 -10 441 -10 1410 l0 1410 -84 0
c-68 0 -85 -3 -90 -16z"/>
<path d="M9037 3449 c-165 -47 -297 -187 -332 -356 -20 -94 -20 -385 0 -473
22 -96 62 -167 130 -236 104 -104 232 -150 395 -141 58 3 117 13 152 25 129
46 233 146 284 272 54 134 56 484 4 623 -22 59 -80 139 -139 194 -110 100
-325 140 -494 92z m249 -208 c50 -23 110 -92 127 -145 8 -27 12 -112 12 -246
0 -194 -1 -208 -24 -257 -56 -125 -178 -174 -296 -119 -57 26 -93 68 -121 141
-16 43 -19 77 -19 235 0 157 3 194 20 245 44 134 180 201 301 146z"/>
<path d="M2200 2855 l0 -595 248 0 c137 0 275 5 307 11 170 31 314 169 360
344 21 82 21 397 0 478 -33 127 -113 232 -222 294 -102 56 -145 63 -435 63
l-258 0 0 -595z m513 371 c44 -19 92 -72 118 -131 18 -41 23 -74 27 -191 7
-215 -15 -310 -90 -382 -44 -42 -82 -52 -195 -52 l-93 0 0 385 0 385 100 0
c63 0 113 -5 133 -14z"/>
<path d="M3466 3148 c-55 -167 -141 -432 -192 -589 -52 -157 -94 -289 -94
-293 0 -3 64 -6 143 -6 l143 0 35 113 35 112 178 0 179 0 13 -40 c7 -22 23
-73 34 -112 l22 -73 144 0 c79 0 144 2 144 5 0 3 -38 121 -84 262 -47 142
-108 328 -135 413 -28 85 -77 235 -108 332 l-58 178 -150 0 -150 0 -99 -302z
m308 -261 c29 -97 51 -179 48 -182 -8 -8 -210 -6 -216 3 -2 4 18 81 45 172 59
197 58 194 65 188 2 -3 29 -85 58 -181z"/>
<path d="M4330 2855 l0 -595 135 0 135 0 0 375 c0 206 4 375 8 375 4 0 42
-102 84 -227 43 -126 99 -293 126 -373 l50 -145 96 -3 c53 -1 96 -1 96 1 0 2
41 123 91 268 50 145 107 312 126 371 19 59 39 110 44 113 5 4 9 -141 9 -375
l0 -381 135 3 c74 2 135 6 135 11 0 4 0 270 0 592 l0 585 -182 0 -183 0 -57
-178 c-181 -566 -211 -653 -219 -640 -5 7 -32 85 -60 173 -28 88 -76 241 -108
340 -32 99 -67 208 -77 242 l-19 63 -182 0 -183 0 0 -595z"/>
<path d="M5780 2855 l0 -595 140 0 140 0 0 240 0 240 220 0 220 0 0 105 0 105
-220 0 -220 0 0 145 0 145 260 0 260 0 0 105 0 105 -400 0 -400 0 0 -595z"/>
<path d="M6710 2855 l0 -595 395 0 395 0 0 105 0 105 -255 0 -255 0 0 150 0
150 210 0 210 0 0 105 0 105 -207 2 -208 3 -3 128 -3 127 256 0 255 0 0 105 0
105 -395 0 -395 0 0 -595z"/>
<path d="M7650 2855 l0 -595 278 0 c295 0 371 9 451 50 148 75 218 249 161
400 -28 74 -74 122 -140 146 l-51 18 34 18 c105 53 156 211 110 344 -23 69
-100 143 -178 172 -94 35 -163 42 -422 42 l-243 0 0 -595z m492 375 c76 -22
117 -115 81 -187 -10 -21 -33 -48 -52 -60 -30 -20 -47 -23 -137 -23 l-103 0
-3 140 -3 140 90 0 c50 0 107 -5 127 -10z m54 -475 c63 -26 84 -63 84 -144 0
-64 -32 -111 -85 -127 -22 -7 -92 -13 -155 -13 l-115 -1 0 150 0 150 118 0
c81 0 129 -5 153 -15z"/>
<path d="M9768 3345 l3 -105 155 0 154 0 0 -490 0 -490 135 0 135 0 0 490 0
490 160 0 160 0 0 105 0 105 -452 0 -453 0 3 -105z"/>
<path d="M5239 1975 c-164 -45 -261 -154 -261 -294 0 -80 25 -144 77 -195 41
-42 173 -110 290 -151 167 -58 215 -96 215 -169 0 -79 -52 -116 -164 -116
-113 0 -171 39 -182 122 l-7 48 -123 0 -124 0 0 -44 c0 -53 22 -118 57 -168
74 -108 272 -171 465 -149 116 13 190 44 254 108 62 62 79 104 79 203 -1 165
-89 254 -345 345 -227 81 -276 131 -220 223 25 41 73 62 140 62 91 0 148 -39
159 -108 l6 -37 123 -3 122 -3 0 51 c0 130 -121 250 -281 280 -71 14 -224 11
-280 -5z"/>
<path d="M5942 1563 l3 -418 33 -67 c72 -147 211 -220 416 -221 203 0 344 73
419 218 l32 60 3 423 3 422 -125 0 -125 0 -3 -397 c-3 -389 -4 -399 -25 -439
-29 -53 -78 -84 -148 -91 -92 -10 -159 18 -201 82 -18 27 -19 56 -24 435 l-5
405 -128 3 -128 3 3 -418z"/>
<path d="M7010 1425 l0 -555 248 0 c319 0 397 14 494 91 78 63 119 206 88 318
-14 53 -95 138 -140 147 -16 4 -30 9 -29 13 0 3 19 20 42 36 66 48 92 104 92
200 -1 128 -50 203 -169 256 -85 37 -184 49 -412 49 l-214 0 0 -555z m481 336
c36 -17 59 -61 59 -111 0 -96 -54 -133 -195 -134 l-90 -1 -3 134 -3 133 103
-4 c57 -3 115 -10 129 -17z m45 -436 c39 -26 57 -62 58 -120 0 -40 -5 -62 -20
-82 -34 -46 -59 -53 -191 -53 l-121 0 0 133 c0 72 3 136 6 141 9 15 241 -1
268 -19z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
